import * as React from 'react';
// import album from './poetshutsclock.jpg';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ScrollDialog from './Dialog.js';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';

const OpenDialog = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



export default function InfoCard({ MediaInfo, cardComp, textURL, imgURL, textTitle, textDesc }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

const [imageSource, setImageSource] = React.useState(textURL);

  React.useEffect(() => {
    if(cardComp === 'iframe'){
      setImageSource(textURL)
    }else{
      setImageSource(imgURL)
    }
  }, [cardComp, textURL, imgURL])

  return (
    <Slide direction="up" in timeout={750} mountOnEnter unmountOnExit
    
    >
    <Card className="link-button" elevation={8} sx={{ width: {xs: '15rem',md: '19rem'}, maxHeight: '70vh', borderRadius: 3, border: 3, borderColor: 'white', margin: '1rem 2rem 5rem 2rem' }}>      <CardMedia
        component={cardComp}
        src={imageSource}
        alt={textTitle}
        sx={{ height: {xs: '11rem', md: '15rem'}}}
      />
      <CardActionArea href={textURL}>
      <CardContent sx={{ display: { xs: 'none', md: 'block' } }}>
        <Typography paragraph variant="h5" color="gold">
          {textTitle}
        </Typography>
        <Typography paragraph variant="h6" color="white">
          {textDesc}
        </Typography>
      </CardContent>
      </CardActionArea>
      <CardActions disableSpacing sx={{ display: 'block' }}>
        <IconButton href={textURL} aria-label="purchase">
          <Tooltip title="Click to purchase!" placement="bottom-start">
            <ShoppingCartIcon />
          </Tooltip>
        </IconButton>

        <OpenDialog
          onClick={handleExpandClick}
          aria-label="show more"
        >
          <ScrollDialog dialogBtn={<Tooltip title="More info..." placement="bottom-start"><InfoOutlinedIcon fontSize='large' /></Tooltip>} dialogTxt={<MediaInfo />} dialogTitle={textTitle} />
        </OpenDialog>
      </CardActions>
    </Card>
    </Slide>
  );
}
