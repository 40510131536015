const pages = [
    { title: 'curio II', loc: "/curioII", tip: "Curio II!" },
    { title: 'cassani / campbell', loc: "/moo", tip: "Just say moo!" },
    { title: 'haar', loc: "/haar", tip: "haar series" },
    { title: 'arranging', loc: "/arr", tip: "string and woodwind arrangements" },
    { title: 'poetry', loc: "/poetry", tip: "Poetry!...David Lynch!!" },
    { title: 'apps', loc: "/apps", tip: "apps" },
    { title: 'film', loc: "/films", tip: "film scores" },
    { title: 'glowalker', loc: "/glow", tip: "glowalker band" },
    { title: 'albums', loc: "/albums", tip: "Albums I've been involved in" },
    { title: 'music videos', loc: "/vids", tip: "Music videos!" },
    { title: 'dance', loc: "/dance", tip: "Dance!" },    
    { title: 'endorsements', loc: "/end", tip: "Endorsements!" },
    { title: 'ads', loc: "/ads", tip: "ads" },
    { title: 'ar/xr', loc: "/arxr", tip: "AR/XR" },
    { title: 'contact', loc: "mailto:info@fraser-campbell.com?hiya", tip: "contact Fraser" },
  ];

  export function Pages() {
    return pages;
  };