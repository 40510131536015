import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import PSCImg from './images/albumCovers/poetshutsclock.jpg';
import TitleChange from './titleChanger.js';


function GlowDetails() {
    return (
        <div>
            <Grid variant='container'>
            <Grid variant='item'>
                    <Typography variant='body1'><a href='https://glowalker.bandcamp.com/album/trouble-is' target='_blank' rel="noreferrer" > Glowalker on bandcamp </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1' color='cornflowerblue'> Band:</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser Campbell - woodwinds, compositions, arrangements</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://jjbyars.bandcamp.com/' target='_blank' rel="noreferrer" > JJ Byars - vocals, lyrics, guitar </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.instagram.com/juliansmithmusic/' target='_blank' rel="noreferrer" > Julian Smith - bass </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.allaboutjazz.com/musicians/nick-anderson' target='_blank' rel="noreferrer" > Nick Anderson - drums </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.francescadardani.com' target='_blank' rel="noreferrer" > Francesca Dardani - violin </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.melodygiron.com' target='_blank' rel="noreferrer" > Melody Giron - cello </a></Typography>
                </Grid>
            </Grid>
        </div >

    )
};

function Glow() {

    TitleChange("Glowalker");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop: { xs: '6rem', md: '4rem' } }} variant="h2" color="gold">Glowalker</Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard MediaInfo={GlowDetails}
                        cardComp="iframe"
                        imgURL={PSCImg}
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=1645329571/size=large/tracklist=true/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        textTitle="Glowalker - Trouble Is" />
                </Grid>
            </Grid >
        </div >
    )
};

export default Glow;